/**
 * Object of Shipping Methods
 */
import ShippingSpeed from './ShippingSpeed';

export default {
  DROP_OFF: {
    value: 'DROP_OFF',
    label: 'Drop Off',
    baseServiceLevel: null,
    baseCost: 0,
    active: true
  },
  EXTERNAL_FULFILLMENT: {
    value: 'EXTERNAL_FULFILLMENT',
    label: 'Ship Yourself',
    baseServiceLevel: null,
    baseCost: 0,
    active: true
  },
  PREPAID_LABEL: {
    value: 'PREPAID_LABEL',
    label: 'Prepaid Label',
    baseServiceLevel: ShippingSpeed.FEDEX_GROUND.code,
    baseCost: 999,
    active: true
  },
  RETURN_MAILER: {
    value: 'RETURN_MAILER',
    label: 'Return Mailer',
    baseServiceLevel: ShippingSpeed.FEDEX_GROUND.code,
    baseCost: 2499,
    active: false
  }
};
