import Api from '../api';

const Public = '/public';

const PublicAPI = {
  generalStats() {
    return Api.get(`${Public}/general-stats`);
  },

  getLocations() {
    return Api.get(`${Public}/locations`);
  }
};

export default PublicAPI;
