import ShippingMethod from '../../Constants/ShippingMethod';
import { find, first, get } from 'lodash';

export default function makeEmptyShipment(user, toCustomer = false) {
  const addresses = get(user, 'addresses', []);
  const address = find(addresses, a => a.primary) || first(addresses);
  const addressId = get(address, 'id');

  return {
    tempKey: Math.random() * 100000,
    customerId: user.id,
    customerAddressId: addressId,
    orderId: null,
    trackingNumber: null,
    method: ShippingMethod.EXTERNAL_FULFILLMENT.value,
    carrier: null,
    serviceLevel: null,
    shippoShipmentId: null,
    shippoRateId: null,
    toCustomer
  };
}
