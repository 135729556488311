import { map } from 'lodash';
import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import LoadingPage from '../views/LoadingPage/LoadingPage';

import LandingPage from '../views/LandingPage/LandingPage';
import PrivateGuard from '../components/PrivateGuard/PrivateGuard';
import ProductsWrapper from '../wrappers/ProductsWrapper';
import Admin from '../containers/Admin/Admin';
import CustomerWrapper from '../wrappers/CustomerWrapper';
import { Redirect, Route, Switch } from 'react-router-dom';
import PageTransition from '../components/PageTransition/PageTransition';
import HomeRouter from './HomeRouter';
import contentPageRoutes from './contentPageRoutes';

const routerConfig = [
  {
    exact: false,
    path: '/admin',
    guard: PrivateGuard,
    wrapper: Admin,
    transition: PageTransition,
    transitionKeyFilter: (toKey, fromKey, exact) => {
      if (
        toKey.includes('orders') ||
        toKey.includes('gallery') ||
        fromKey.includes('gallery') ||
        fromKey === toKey ||
        !exact
      )
        return '';
    },
    routes: [
      {
        exact: true,
        path: '/admin/messengers',
        component: lazy(() => import('../containers/Messengers/Messengers'))
      },
      {
        exact: true,
        path: '/admin/messengers/:messengerId',
        component: lazy(() => import('../containers/Messengers/Messenger'))
      },
      {
        exact: true,
        path: '/admin/messengers/:messengerId/printable-routes',
        component: lazy(() =>
          import('../containers/Messengers/PrintableRoutes')
        )
      },
      {
        exact: true,
        path: '/admin/products/:productId/details/:productDetailsId',
        component: lazy(() => import('../containers/Products/ProductDetails'))
      },
      {
        exact: true,
        path: '/admin/products/:productId',
        component: lazy(() => import('../containers/Products/Product'))
      },
      {
        exact: true,
        path: '/admin/products',
        component: lazy(() => import('../containers/Products/Products'))
      },
      {
        exact: true,
        path: '/admin/social-submissions',
        component: lazy(() =>
          import(
            '../views/SocialMediaSubmissionsPage/SocialMediaSubmissionsPage'
          )
        )
      },
      {
        exact: true,
        path: '/admin/roll-events-dashboard',
        component: lazy(() =>
          import('../views/RollEventsDashboardPage/RollEventsDashboardPage')
        )
      },
      {
        path: '/admin/roll-events-dashboard/:event',
        component: lazy(() =>
          import('../views/RollEventsDashboardPage/RollEventsDashboardPage')
        )
      },
      {
        exact: true,
        path: '/admin/orders',
        component: lazy(() => import('../containers/Orders/AdminOrders'))
      },
      {
        exact: false,
        path: '/admin/users/:userId',
        routes: [
          {
            exact: true,
            path: '/admin/users/:userId/settings',
            wrapper: CustomerWrapper,
            component: lazy(() => import('../containers/Users/UserSettings'))
          },
          {
            exact: true,
            path: '/admin/users/:userId/referrals',
            wrapper: CustomerWrapper,
            component: lazy(() => import('../containers/Users/UserReferrals'))
          },
          {
            exact: true,
            path: '/admin/users/:userId/orders',
            wrapper: CustomerWrapper,
            component: lazy(() => import('../containers/Orders/Orders'))
          },
          {
            exact: true,
            path: '/admin/users/:userId/small-print-orders',
            wrapper: CustomerWrapper,
            component: lazy(() =>
              import('../containers/Prints/SmallPrintOrder')
            )
          },
          {
            exact: true,
            path: '/admin/users/:userId/print-orders/:printId',
            wrapper: CustomerWrapper,
            component: lazy(() => import('../containers/Prints/PrintOrder'))
          },
          {
            exact: true,
            path: '/admin/users/:userId/orders/:orderId/summary',
            wrapper: CustomerWrapper,
            component: lazy(() => import('../containers/Orders/OrderSummary'))
          },
          {
            exact: true,
            path: '/admin/users/:userId/orders/:orderId/return-negatives-form',
            wrapper: CustomerWrapper,
            component: lazy(() =>
              import('../views/ReturnNegativesFormPage/ReturnNegativesFormPage')
            )
          },
          {
            exact: true,
            path: '/admin/users/:userId/orders/:orderId/add-rush',
            wrapper: CustomerWrapper,
            component: lazy(() => import('../views/AddRushPage/AddRushPage'))
          },
          {
            exact: false,
            path: '/admin/users/:userId/orders/:orderId',
            wrapper: CustomerWrapper,
            component: lazy(() => import('../containers/Orders/Order'))
          },
          {
            exact: true,
            path: '/admin/users/:userId/rolls/:rollId',
            wrapper: CustomerWrapper,
            component: lazy(() => import('../containers/Users/UserRoll'))
          },
          {
            exact: true,
            path: `/admin/users/:userId/gallery/:galleryImageId`,
            wrapper: CustomerWrapper,
            component: lazy(() => import('../containers/Gallery/Gallery'))
          },
          {
            exact: true,
            path: '/admin/users/:userId/rolls/:rollId/digital-contact-sheet',
            wrapper: CustomerWrapper,
            component: lazy(() =>
              import(`views/DigitalContactSheetPage/DigitalContactSheetPage`)
            )
          },
          {
            exact: false,
            path: '/admin/users/:userId',
            wrapper: CustomerWrapper,
            component: lazy(() => import('../containers/Users/User'))
          }
        ]
      },
      {
        component: () => <Redirect to="/" />
      }
    ]
  },
  {
    exact: false,
    path: '/vendor-orders',
    guard: PrivateGuard,
    transition: PageTransition,
    routes: [
      {
        exact: true,
        path: '/vendor-orders',
        component: lazy(() => import('../containers/Orders/VendorOrders'))
      }
    ]
  },
  {
    exact: false,
    path: '/users/:userId',
    guard: PrivateGuard,
    wrapper: CustomerWrapper,
    transition: PageTransition,
    transitionKeyFilter: (toKey, fromKey, exact) => {
      if (
        toKey.includes('orders') ||
        toKey.includes('gallery') ||
        fromKey.includes('gallery') ||
        fromKey === toKey ||
        !exact
      )
        return '';

      return toKey.includes('users') ? toKey : '';
    },
    routes: [
      {
        exact: true,
        path: '/users/:userId/settings',
        component: lazy(() => import('../containers/Users/UserSettings'))
      },
      {
        exact: true,
        path: '/users/:userId/referrals',
        component: lazy(() => import('../containers/Users/UserReferrals'))
      },
      {
        exact: true,
        path: '/users/:userId/orders',
        component: lazy(() => import('../containers/Orders/Orders'))
      },
      {
        exact: false,
        path: '/users/:userId/print-orders/:printId',
        component: lazy(() => import('../containers/Prints/PrintOrder'))
      },
      {
        exact: true,
        path: '/users/:userId/small-print-orders',
        component: lazy(() => import('../containers/Prints/SmallPrintOrder'))
      },
      {
        exact: true,
        path: '/users/:userId/orders/:orderId/summary',
        component: lazy(() => import('../containers/Orders/OrderSummary'))
      },
      {
        exact: true,
        path: '/users/:userId/orders/:orderId/return-negatives-form',
        component: lazy(() =>
          import('../views/ReturnNegativesFormPage/ReturnNegativesFormPage')
        )
      },
      {
        exact: true,
        path: '/users/:userId/orders/:orderId/add-rush',
        component: lazy(() => import('../views/AddRushPage/AddRushPage'))
      },
      {
        exact: false,
        path: '/users/:userId/orders/:orderId',
        component: lazy(() => import('../containers/Orders/Order'))
      },
      {
        exact: true,
        path: '/users/:userId/rolls/:rollId',
        component: lazy(() => import('../containers/Users/UserRoll'))
      },
      {
        exact: true,
        path: `/users/:userId/gallery/:galleryImageId`,
        component: lazy(() => import('../containers/Gallery/Gallery'))
      },
      {
        exact: true,
        path: '/users/:userId/rolls/:rollId/digital-contact-sheet',
        component: lazy(() =>
          import(`views/DigitalContactSheetPage/DigitalContactSheetPage`)
        )
      },
      {
        exact: false,
        path: '/users/:userId',
        component: lazy(() => import('../containers/Users/User'))
      },
      {
        component: () => <Redirect to="/" />
      }
    ]
  },
  {
    exact: false,
    path: '/',
    transition: PageTransition,
    transitionKeyFilter: key => {
      return key.includes('orders') ||
        key.includes('users') ||
        key.includes('onboarding')
        ? ''
        : key;
    },
    routes: [
      {
        exact: true,
        path: '/',
        component: HomeRouter
      },
      {
        exact: true,
        path: '/lab',
        component: LandingPage
      },
      {
        exact: true,
        path: '/club',
        component: LandingPage
      },
      ...contentPageRoutes,
      {
        exact: false,
        path: '/shared-scans/:scanCode',
        component: lazy(() => import('../views/SharedScanPage/SharedScanPage'))
      },
      {
        exact: true,
        path: '/frequently-asked-questions',
        component: lazy(() => import('../views/FAQPage/FAQPage'))
      },
      {
        exact: true,
        path: '/about',
        component: lazy(() => import('../views/AboutPage/AboutPage'))
      },
      {
        exact: true,
        path: '/pricing',
        component: lazy(() => import('../views/PricingPage/PricingPage'))
      },
      {
        exact: false,
        path: '/memberships',
        component: lazy(() =>
          import('../views/PurchaseMembershipPage/PurchaseMembershipPage')
        )
      },
      {
        exact: true,
        path: '/login',
        component: lazy(() => import('../views/LoginPage/LoginPage'))
      },
      {
        exact: true,
        path: '/signup',
        component: lazy(() => import('../views/SignupPage/SignupPage'))
      },
      {
        exact: true,
        path: '/password-reset',
        component: lazy(() =>
          import('../views/PasswordResetPage/PasswordResetPage')
        )
      },
      {
        exact: true,
        path: '/privacy-policy',
        component: lazy(() =>
          import('../views/PrivacyPolicyPage/PrivacyPolicyPage')
        )
      },
      {
        exact: true,
        path: '/terms',
        component: lazy(() => import('../views/TermsPage/TermsPage'))
      },
      {
        exact: true,
        path: '/checkout',
        guard: PrivateGuard,
        component: lazy(() => import('../containers/Orders/Checkout'))
      },
      {
        exact: true,
        path: '/products/all',
        guard: PrivateGuard,
        wrapper: ProductsWrapper,
        component: lazy(() =>
          import('../containers/Products/ProductCollection')
        )
      },
      {
        exact: true,
        path: '/products/:productName/detailed',
        guard: PrivateGuard,
        wrapper: ProductsWrapper,
        component: lazy(() => import('../containers/Products/DetailedProduct'))
      },
      {
        exact: true,
        path: '/complete-user-details',
        guard: PrivateGuard,
        component: lazy(() => import('../containers/Users/CompleteUserDetails'))
      },
      {
        exact: false,
        guard: PrivateGuard,
        path: '/onboarding',
        component: lazy(() => import('../containers/Onboarding/Onboarding'))
      }
    ]
  }
];

function PassThroughComponent(props) {
  const children = React.Children.map(props.children, child =>
    React.isValidElement(child) ? React.cloneElement(child, props) : child
  );

  return <React.Fragment>{children}</React.Fragment>;
}

PassThroughComponent.propTypes = {
  children: PropTypes.node
};

export function renderRoutes(routes, props = {}) {
  return (
    <Suspense fallback={<LoadingPage />}>
      <Switch location={props.location}>
        {routes.map((route, i) => {
          const Guard = route.guard || React.Fragment;
          const Wrapper = route.wrapper || React.Fragment;
          const Transition = route.transition || PassThroughComponent;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={routeProps => {
                const guardProps = route.guard ? props : [];
                const wrapperProps = route.wrapper
                  ? { ...props, ...routeProps }
                  : [];
                const transitionProps = route.transition
                  ? {
                      ...props,
                      ...routeProps,
                      paths: map(route.routes, 'path'),
                      keyFilter: route.transitionKeyFilter
                    }
                  : [];

                return (
                  <Guard {...guardProps}>
                    <Wrapper {...wrapperProps}>
                      <Transition {...transitionProps}>
                        {route.routes ? (
                          renderRoutes(route.routes, props)
                        ) : (
                          <Component {...props} {...routeProps} />
                        )}
                      </Transition>
                    </Wrapper>
                  </Guard>
                );
              }}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
}

export default function Routes(props) {
  return renderRoutes(routerConfig, props);
}
