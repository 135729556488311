import qs from 'qs';

import Api from '../api';

const Order = '/orders';

const OrderAPI = {
  add(data) {
    return Api.post(`${Order}`, { data });
  },

  get(orderId) {
    return Api.get(`${Order}/${orderId}`);
  },

  getAll(params) {
    return Api.get(`${Order}?${qs.stringify(params)}`);
  },

  update(orderId, data) {
    return Api.put(`${Order}/${orderId}`, { data });
  },

  attachPayment(orderId, paymentId) {
    return Api.put(`${Order}/${orderId}/attach-payment`, {
      data: { paymentId }
    });
  },

  invoice(orderId) {
    return Api.put(`${Order}/${orderId}/invoice`);
  },

  sendInvoice(orderId) {
    return Api.put(`${Order}/${orderId}/send-invoice`);
  },

  cancel(orderId) {
    return Api.put(`${Order}/${orderId}/cancel`);
  },

  complete(orderId) {
    return Api.put(`${Order}/${orderId}/complete`);
  },

  markPrintsReady(orderId) {
    return Api.put(`${Order}/${orderId}/mark-prints-ready`);
  },

  addRollsEvent(orderId, event, data) {
    return Api.put(`${Order}/${orderId}/add-rolls-event/${event}`, { data });
  },

  receiveOrder(orderId) {
    return Api.put(`${Order}/${orderId}/receive`);
  },

  getShipments(orderId) {
    return Api.get(`${Order}/${orderId}/shipments`);
  }
};

export default OrderAPI;
